<template>
	<!-- 免费领取列表 -->
	<div class="adhibition-freeland-set el-content">
		<a-table :pagination="false" row-key="id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'姓名',dataIndex:'user_name'},
			{title:'联系方式',dataIndex:'phone'},
			{title:'种植状态',dataIndex:'condition',slots:{customRender:'give_status'}},
			{title:'领取时间',dataIndex:'create_time'},
			{title:'到期时间',dataIndex:'expire_date'},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" >
			<template #give_status="{record}">
				<a-tag color="#999" v-if="record.give_status == 1">未种植</a-tag>
				<a-tag color="#00CC66" v-if="record.give_status == 2">已种植</a-tag>
				<a-tag color="#FF0066" v-if="record.give_status == 3">已过期</a-tag>
			</template>
			<template #action="{record}">
				<a-space>
					<a-button type="link" size="small" v-if="record.status == 1" @click="del('del',record.id)">禁用</a-button>
					<a-button type="link" size="small" v-if="record.status == 0" @click="del('recovery',record.id)">恢复使用</a-button>
				</a-space>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getFreeLandEventList(info.page,e)}"
				@change="(e)=>{getFreeLandEventList(e,info.limit)}"
			/>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import freeLandModel from '@/api/addons/freeLand'
export default{
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			}
		})
		getGetList(1,state.info.limit)

		function getGetList(page,limit){
			let param = { page,limit}
			freeLandModel.getFreeLandGet(page,limit,res=>state.info = {limit,...res})
		}

		const del = (type,id)=>freeLandModel.handleFreeLandGet(type,id,()=>{
			getGetList(state.info.page,state.info.limit)
		})

		return{
			...toRefs(state),
			getGetList,
			del
		}
	}
}
</script>

<style>
</style>
